<template>
    <section class="partnership my-5">
        <h2 class="partnership__title mt-3">Контакты</h2>

        <div class="accordion-horizontal border-0 mt-5 d-none d-lg-flex">
            <input type="radio" name="select" class="accordion-horizontal-select" checked />
            <div class="accordion-horizontal-title">
                <span>Дирекция ЦТТ</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Дирекция ЦТТ
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-fm.svg" alt="">
                    </div>

                    <div class="contact-card__name">

                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li class="mx-2">
                            <a href="tel:+78124809999">
                                +7 (812) 480-99-99
                            </a>
                        </li>
                        <li class="mx-2">
                            <a href="mailto:tt@itmo.ru">
                                TT.ITMO.RU
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <input type="radio" name="select" class="accordion-horizontal-select" />
            <div class="accordion-horizontal-title">
                <span>Услуги в сфере права в области ИС</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Услуги в сфере права в области ИС
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-m.svg" alt="">
                    </div>

                    <div class="contact-card__name mt-auto">
                        <h3>Денисов Денис Сергеевич</h3>
                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li>
                            <a href="mailto:dsdenisov@itmo.ru">
                                dsdenisov@itmo.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div> 
            
            <input type="radio" name="select" class="accordion-horizontal-select" />
            <div class="accordion-horizontal-title">
                <span>Услуги патентной аналитики и ДПО в области ТТ</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Услуги патентной аналитики и ДПО в области ТТ
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-m.svg" alt="">
                    </div>

                    <div class="contact-card__name mt-auto">
                        <h3>Николаев Андрей Сергеевич</h3>
                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li>
                            <a href="mailto:nikand@itmo.ru">
                                nikand@itmo.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div> 

            <input type="radio" name="select" class="accordion-horizontal-select" />
            <div class="accordion-horizontal-title">
                <span>Акселерация проектов и проведение хакатонов</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Акселерация проектов и проведение хакатонов
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-f.svg" alt="">
                    </div>

                    <div class="contact-card__name mt-auto">
                        <h3>Баранюк Олеся Александровна</h3>
                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li>
                            <a href="mailto:oabaraniuk@itmo.ru">
                                oabaraniuk@itmo.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div> 

            <input type="radio" name="select" class="accordion-horizontal-select" />
            <div class="accordion-horizontal-title">
                <span>Услуги в области маркетинга и финансов</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Услуги в области маркетинга и финансов
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-m.svg" alt="">
                    </div>

                    <div class="contact-card__name mt-auto">
                        <h3>Хмелевский Сергей Владимирович</h3>
                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li>
                            <a href="mailto:skhmelevsky@itmo.ru">
                                skhmelevsky@itmo.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div> 

            <input type="radio" name="select" class="accordion-horizontal-select" />
            <div class="accordion-horizontal-title">
                <span>Цифровые сервисы ТТ</span>
                <div class="arrow"></div>
            </div>
            <div class="accordion-horizontal-content p-0 px-4">
                <div class="contact-card p-3 p-lg-5">
                    <div class="d-flex w-100 justify-content-between align-items-center">
                        <h3 class="contact-card__title">
                            Цифровые сервисы ТТ
                        </h3>
                        <button class="btn contact-arrow"></button>
                    </div>

                    <div class="contact-card__image">
                        <img src="../../assets/contacts-m.svg" alt="">
                    </div>

                    <div class="contact-card__name mt-auto">
                        <h3>Плугин Кирилл Дмитриевич</h3>
                    </div>

                    <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                        <li>
                            <a href="mailto:KirillPlugin@itmo.ru">
                                KirillPlugin@itmo.ru
                            </a>
                        </li>
                    </ul>
                </div>
            </div> 
        </div> 

        <div class="accordion border-0 mt-5 d-block d-lg-none" id='contactsAccordion'>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="ttcDirection">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#ttcDirectionCollapse" aria-expanded="true" aria-controls="ttcDirectionCollapse">
                        Дирекция ЦТТ
                    </button>
                </h2>
                <div id="ttcDirectionCollapse" class="accordion-collapse collapse show" aria-labelledby="ttcDirection" data-bs-parent="#contactsAccordion">
                <div class="accordion-body px-0">
                    <div class="contact-card p-3 p-lg-5">
                        <div class="d-flex w-100 justify-content-between align-items-center">
                            <h3 class="contact-card__title">
                                Дирекция ЦТТ
                            </h3>
                            <button class="btn contact-arrow"></button>
                        </div>

                        <div class="contact-card__image">
                            <img src="../../assets/contacts-fm.svg" alt="">
                        </div>

                        <div class="contact-card__name">

                        </div>

                        <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                            <li class="mx-2">
                                <a href="tel:+78124809999">
                                    +7 (812) 480-99-99
                                </a>
                            </li>
                            <li class="mx-2">
                                <a href="mailto:tt@itmo.ru">
                                    TT.ITMO.RU
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </div>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="lawServices">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#lawServicesCollapse" aria-expanded="false" aria-controls="lawServicesCollapse">
                    Услуги в сфере права в области ИС
                </button>
                </h2>
                <div id="lawServicesCollapse" class="accordion-collapse collapse" aria-labelledby="lawServices" data-bs-parent="#contactsAccordion">
                    <div class="accordion-body px-0">
                        <div class="contact-card p-3 p-lg-5">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <h3 class="contact-card__title">
                                    Услуги в сфере права в области ИС
                                </h3>
                                <button class="btn contact-arrow"></button>
                            </div>
        
                            <div class="contact-card__image">
                                <img src="../../assets/contacts-m.svg" alt="">
                            </div>
        
                            <div class="contact-card__name mt-auto">
                                <h3>Денисов Денис Сергеевич</h3>
                            </div>
        
                            <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                                <li>
                                    <a href="mailto:dsdenisov@itmo.ru">
                                        dsdenisov@itmo.ru
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="patentAnalysis">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#patentAnalysisCollapse" aria-expanded="false" aria-controls="patentAnalysisCollapse">
                    Услуги патентной аналитики и ДПО в области ТТ
                </button>
                </h2>
                <div id="patentAnalysisCollapse" class="accordion-collapse collapse" aria-labelledby="patentAnalysis" data-bs-parent="#contactsAccordion">
                    <div class="accordion-body px-0">
                        <div class="contact-card p-3 p-lg-5">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <h3 class="contact-card__title">
                                    Услуги патентной аналитики и ДПО в области ТТ
                                </h3>
                                <button class="btn contact-arrow"></button>
                            </div>
        
                            <div class="contact-card__image">
                                <img src="../../assets/contacts-m.svg" alt="">
                            </div>
        
                            <div class="contact-card__name mt-auto">
                                <h3>Николаев Андрей Сергеевич</h3>
                            </div>
        
                            <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                                <li>
                                    <a href="mailto:nikand@itmo.ru">
                                        nikand@itmo.ru
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="hackathons">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#hackathonsCollapse" aria-expanded="false" aria-controls="hackathonsCollapse">
                    Акселерация проектов и проведение хакатонов
                </button>
                </h2>
                <div id="hackathonsCollapse" class="accordion-collapse collapse" aria-labelledby="hackathons" data-bs-parent="#contactsAccordion">
                    <div class="accordion-body px-0">
                        <div class="contact-card p-3 p-lg-5">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <h3 class="contact-card__title">
                                    Акселерация проектов и проведение хакатонов
                                </h3>
                                <button class="btn contact-arrow"></button>
                            </div>
        
                            <div class="contact-card__image">
                                <img src="../../assets/contacts-f.svg" alt="">
                            </div>
        
                            <div class="contact-card__name mt-auto">
                                <h3>Баранюк Олеся Александровна</h3>
                            </div>
        
                            <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                                <li>
                                    <a href="mailto:oabaraniuk@itmo.ru">
                                        oabaraniuk@itmo.ru
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="marketing">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#marketingCollapse" aria-expanded="false" aria-controls="marketingCollapse">
                    Услуги в области маркетинга и финансов
                </button>
                </h2>
                <div id="marketingCollapse" class="accordion-collapse collapse" aria-labelledby="marketing" data-bs-parent="#contactsAccordion">
                    <div class="accordion-body px-0">
                        <div class="contact-card p-3 p-lg-5">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <h3 class="contact-card__title">
                                    Услуги в области маркетинга и финансов
                                </h3>
                                <button class="btn contact-arrow"></button>
                            </div>
        
                            <div class="contact-card__image">
                                <img src="../../assets/contacts-m.svg" alt="">
                            </div>
        
                            <div class="contact-card__name mt-auto">
                                <h3>Хмелевский Сергей Владимирович</h3>
                            </div>
        
                            <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                                <li>
                                    <a href="mailto:skhmelevsky@itmo.ru">
                                        skhmelevsky@itmo.ru
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="accordion-item border-0">
                <h2 class="accordion-header" id="digitalServices">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#digitalServicesCollapse" aria-expanded="false" aria-controls="digitalServicesCollapse">
                    Цифровые сервисы ТТ
                </button>
                </h2>
                <div id="digitalServicesCollapse" class="accordion-collapse collapse" aria-labelledby="digitalServices" data-bs-parent="#contactsAccordion">
                    <div class="accordion-body px-0">
                        <div class="contact-card p-3 p-lg-5">
                            <div class="d-flex w-100 justify-content-between align-items-center">
                                <h3 class="contact-card__title">
                                    Цифровые сервисы ТТ
                                </h3>
                                <button class="btn contact-arrow"></button>
                            </div>
        
                            <div class="contact-card__image">
                                <img src="../../assets/contacts-m.svg" alt="">
                            </div>
        
                            <div class="contact-card__name mt-auto">
                                <h3>Плугин Кирилл Дмитриевич</h3>
                            </div>
        
                            <ul class="contact-card__contacts list-unstyled d-flex flex-column flex-md-row">
                                <li>
                                    <a href="mailto:KirillPlugin@itmo.ru">
                                        KirillPlugin@itmo.ru
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        </div> 
    </section>
</template>

<script>
import routerMixin from "@/mixins/routerMixin";

export default {
    data() {
        return {
            directions: true,
        }
    },

    async mounted() {
        const { isMenuVisible, toggleMenu } = routerMixin();

        if (isMenuVisible.value) {
            await toggleMenu()
        }

        document.body.scrollIntoView({ behavior: "smooth" });
    }
}
</script>

<style>
.accordion-horizontal {
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    width: 100%;
}

.accordion-horizontal-select {
    cursor: pointer;
    margin: 0 16px;
    opacity: 0;
    z-index: 1;
}

.accordion-horizontal-title {
    position: relative;
}

.accordion-horizontal-title .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.accordion-horizontal-title .arrow::after {
    content: url('../../assets/arrow-left-outline.svg');
}

.contact-arrow::after {
    content: url('../../assets/arrow-down-outline.svg');
}

.accordion-horizontal-title:not(:nth-last-child(2))::after {
    border: 1px solid transparent;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.accordion-horizontal-title span {
    bottom: 0px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    white-space: nowrap;
    width: 100%;

    font-weight: 600;
    font-size: 20px;
    line-height: 42px;

    color: #2A2822;
}

.accordion-horizontal-content {
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    /* transition: margin 0.5s ease 0.5s; */
    transition: ease 0.5s;
}

.accordion-horizontal-select:checked + .accordion-horizontal-title + .accordion-horizontal-content {
    margin-bottom: 0;
    margin-right: 0;
}

/* Generated styles starts here */

.accordion-horizontal {
    /* border-color: #dedede; */
    /* border-radius: 8px; */
    border-style: solid;
    border-width: 1px;
    flex-direction: row;
    height: 600px;
} 

.accordion-horizontal-title,
.accordion-horizontal-select  {
    background-color: #f2f2f2;
    color: #7f8787;
    width: 65px;
    height: 100%;
    font-size: 15px;
}

.accordion-horizontal-select {
    margin-bottom: -65px;
    margin-right: -65px;
}

.accordion-horizontal-title:not(:nth-last-child(2))::after {
    /* border-bottom-color: transparent; */
    /* border-color: rgb(234, 234, 234); */
} 

/* .accordion-horizontal-select:hover + .accordion-horizontal-title, */
.accordion-horizontal-select:checked + .accordion-horizontal-title {
    /* background-color: #ffffff; */
    display: none;
} 

 .accordion-horizontal-title span  {	
    transform: rotate(-90deg);
    -ms-writing-mode: lr-bt;
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    padding-bottom: 33px;
    padding-top: 33px;
    line-height: 65px;
} 

.accordion-horizontal-content {
    background-color: #fff;
    /* color: #7f8787; */
    height: 100%;
    margin-bottom: 0;
    margin-right: calc(-1 * calc(100% - 400px));
    padding: 30px;
    width: calc(100% - 400px);
} 

.contact-card {
    background: #304999;
    color: #fff;
    height: 100%;
    width: 100%;
    border-radius: 10px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.contact-card__contacts a {
    color: #fff;
}

.accordion-button {
    background-color: #f2f2f2;
    color: #2A2822;
}

@media screen and (max-width: 500px) {
    .contact-card__image img {
        max-width: 250px;
    }
}
</style>